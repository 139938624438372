







import { Component, Vue } from 'vue-property-decorator';
import RpsChoice from '@/components/RpsChoice.vue';
import RpsBetAmountChoice from '@/components/RpsBetAmountChoice.vue';

@Component({
  components: {
    RpsChoice,
    RpsBetAmountChoice,
  },
})
export default class RpsGame extends Vue {}


























































































































import {
  Component,
  Vue,
  Prop,
  Emit,
} from 'vue-property-decorator';
import RpsCreateGame from '@/components/RpsCreateGame.vue';

@Component({
  components: {
    RpsCreateGame,
  },
})
export default class RspGameDialog extends Vue {
  @Prop({ type: Boolean, required: true }) open: boolean|undefined;

  private tab: string = 'games';

  get dialog() {
    return this.open;
  }

  @Emit('close-dialog')
  // eslint-disable-next-line class-methods-use-this
  close() {
    return false;
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{class:{
        'rps-choice': true,
        rock: true,
        blue: true,
        selected: _vm.selectedChoice === 'rock'
      },on:{"click":function($event){return _vm.onChoiceClick('rock')}}},[_c('v-icon',{attrs:{"size":"64"}},[_vm._v(" fas fa-hand-rock ")])],1)]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{class:{
        'rps-choice': true,
        scissor: true,
        green: true,
        selected: _vm.selectedChoice === 'scissor'
      },on:{"click":function($event){return _vm.onChoiceClick('scissor')}}},[_c('v-icon',{attrs:{"size":"64"}},[_vm._v(" fas fa-hand-scissors ")])],1)]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{class:{
        'rps-choice': true,
        red: true,
        paper: true,
        selected: _vm.selectedChoice === 'paper'
      },on:{"click":function($event){return _vm.onChoiceClick('paper')}}},[_c('v-icon',{attrs:{"size":"64"}},[_vm._v(" fas fa-hand-paper ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
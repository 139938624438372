

















































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class RpsBetAmountChoice extends Vue {
  private betAmount: number = 0;

  // eslint-disable-next-line class-methods-use-this
  get choices() {
    return [
      5000,
      2500,
      1500,
      1000,
      500,
      400,
      300,
      200,
      100,
      50,
      20,
      10,
    ];
  }
}




















































import { Vue, Component } from 'vue-property-decorator';

@Component
export default class RpsChoice extends Vue {
  private selectedChoice: string = '';

  onChoiceClick(choice: string): void {
    this.selectedChoice = choice;
  }
}
